import { createRouter, createWebHashHistory } from 'vue-router'
import NotFound from '@/components/NotFound.vue';
const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=>import('../views/HomeView.vue')
  },
  {
    path: '/bsq',
    name: 'AIIntroduce',
    component: ()=>import('../views/AIIntroduceView.vue')
  },
  {
    path: '/shengbei',
    name: 'FJBView',
    component: ()=>import('../views/FJBView.vue')
  },
  {
    path:  '/:pathMatch(.*)*', // 使用正则表达式捕获所有剩余路径
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


export default router
